.container {
    padding: 40px 40px 120px;
    max-width: 1140px;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    
}

.container_inner {
    padding: 40px 80px;
    min-height: 440px;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 16px;
    cursor: pointer;
    /* text-align: center; */
    aspect-ratio: 16 / 9;
}

.title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
}

.body {
    padding: 23px 0 40px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 60px;
    color: white;
    text-shadow: 0px 2px 8px 2px rgba(0,0,0,0.75);    
}

.button {
    display: inline-block;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 30px;
    cursor: pointer;
}

.card_container {
    text-align: center;
    display: flex;
    flex-direction: row;
    transform: translateY(80px);
    max-width: 100%;
    overflow-x: scroll;
}

.card {
    width: 200px;
    height: 260px;
    margin-right: 40px;
    background-color: white;
    border-radius: 16px;
}

@media screen and (max-width: 768px) {
    .body {
        font-size: 32px;
        line-height: 40px;
    }
}