.primary {
    background: #489F67;
    border: 2px solid #489F67;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 24px;
    display: inline-block;
    cursor: pointer;
}

.primary:hover {
    background: #009146;
    border: 2px solid #009146;
}

.primary_cancel {
    background: #F0585B;
    border: 2px solid #F0585B;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 24px;
    display: inline-block;
    cursor: pointer;
}

.primary_cancel:hover {
    background: #FF0000;
    border: 2px solid #FF0000;
}

.secondary {
    border: 1px solid #489F67;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #489F67;
    padding: 11px 25px;
    display: inline-block;
    cursor: pointer;
}

.secondary:hover {
    background-color: #CDC9C4;
}