.content_container {
    margin: 0;
    background-color: #fff;
    padding: 20px 0;
}

.inner {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
}

.breadcrumb {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #100903;
    margin-bottom: 8px;
}