.navigation_container {
    position: sticky;
    top: 0;
    z-index: 9999;
}

.container {
    background-color: #ece8e2;
}

.contents_container {
    margin: 0 auto;
    max-width: 1140px;
    padding: 40px 80px;
}

.heading_name {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 53px;
    color: #100903;
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.sidebar_item {
    display: flex;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 49px;
    color: #489F67;
    cursor: pointer;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-left: 5px;
}

.sidebar_item:hover {
    background-color: #e7e2db;
}

.sidebar_item:last-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.sidebar_item_icon {
    margin-right: 8px;
}

.body_container {
    display: flex;
}

.body_right {
    flex: 1; 
    padding-left: 80px;
}

@media screen and (max-width: 768px) {
    .contents_container {
        padding: 40px 36px;
    }

    .body_container {
        display: block;
    }

    .body_right {
        padding-left: 0;
        padding-top: 32px;
    }
}