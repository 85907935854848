.container {
    padding: 190px 80px 80px;
    margin: 0 auto;
    max-width: 1140px;
}

.iframe_container {
    margin-top: 20px;
    position: relative;
    padding-top: 56.25%;
}

.iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
}

.pill {
    display: inline-block;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #504F4D;
    padding: 11px 20px;
    border: 2px solid #c6c3bf;
    border-radius: 8px;
    margin-right: 20px;
    cursor: pointer;
}

.pill_selected {
    font-weight: 700;
    font-size: 16px;
    border: 2px solid #504F4D;
}

.social_title {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 45px;
    color: #000000;
    margin-top: 80px;
    margin-bottom: 20px;
}

.social_container {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.social_square {
    flex: 1;
    border-radius: 16px;
    background: gray;
    aspect-ratio: 1/1;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.social_icon {
    height: auto;
    width: 145px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
    .container {
        padding: 190px 40px 80px;
    }

    .social_icon {
        width: 80%;
    }

    .pill {
        margin-bottom: 16px;
    }
}