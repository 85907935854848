.container {
    width: 100%;
    background-color:black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    display: flex;
    flex-direction: column-reverse;
}

.container_inner {

}

.video_wrapper {
    position: relative;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .75;
}

.video {
    object-fit: cover;
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
}

.content_gradient_overlay {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 814px;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    mix-blend-mode: multiply;
    opacity: 0.75;
}

.content_container {
    position: absolute;
    left: 10%;
    bottom: 320px;
    width: 100%;
    max-width: calc(100% - 160px);
}

.title {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-size: 80px;
    line-height: 160px;
    color: #FFFFFF;
    overflow: hidden;
}

.body {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 12px 0 40px;
    max-width: 800px;
}

.button {
    display: inline-block;
    /* border: 2px solid #FFFFFF; */
    border-radius: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    /* color: #FFFFFF; */
    padding: 10px 30px;
    cursor: pointer;

    border: 2px solid #FFF200;
    color: #FFF200;
    /* background-color: yellow; */
}

.indicator_container {
    display: flex;
    flex-direction: row;
}

.indicator {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 20px;
    background-color: white;
    cursor: pointer;
}

.indicator_selected {
    background-color: #F9DF4B;
}

.indicator_flex {
    flex: 1;
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 60px;
        line-height: 76px;
        margin-bottom: 16px;
    }
    
    .body {
        font-size: 20px;
        line-height: 30px;
        /* display: none; */
    }

    .content_container {
        bottom: 15%;
        padding: 0px 40px;
        width:calc(100% - 80px)
    }

    .indicator_container {
        margin-top: 36px;
    }

    .indicator_flex {
        flex: none;
    }
}

@media screen and (max-width: 500px) {
    .title {
        font-size: 48px;
        line-height: 54px;
        margin-bottom: 16px;
    }
    
    .body {
        font-size: 16px;
        line-height: 22px;
    }

    .content_container {
        width: 100%;
        max-width: 70%;
        padding: 0;
    }

    .indicator_container {
        margin-top: 36px;
    }

    .indicator_flex {
        flex: none;
    }
}