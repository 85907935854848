.container {
    background-color: #17130F;
    display: flex;
    flex-direction: row;
}

.left {
    flex: 1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 43px;
    padding: 0 0 0 32px;
    color: #FFFFFF;
}

.right {
    flex: 1;
    text-align: right;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 43px;
    margin-right: 24px;
    text-align: right;
    color: #FFFFFF;
}

.navigation_item {
    display: inline-block;
    margin-right: 32px;
    cursor: pointer;
}

.navigation_item:hover {
    color: yellow;
}

.navigation_item:hover > img {
    filter: invert(74%) sepia(89%) saturate(561%) hue-rotate(3deg) brightness(109%) contrast(105%);;
}

.navigation_welcome {
    display: inline-block;
    cursor: pointer;
}

.sign_in {
    padding: 0 24px;
    color: #FFF200;
    border-left: 1px solid #45423f;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 43px;
    cursor: pointer;
}

.card_icon {
    height: 18px;
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(185deg) brightness(105%) contrast(102%);
}


@media screen and (max-width: 768px) {
    .left {
        display: none;
    }
    .left_inner {
        display: none;
    }

    .container {
        flex-direction: row-reverse;
    }
    
    .right {
        display: none;
    }
}