.container {
    background-color: #2F392B;
}

.container_inner {
    max-width: 1140px;
    padding: 60px;
    margin: 0 auto;
}

.content_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image_container {
    position: relative;
    flex: 1;
    max-width: 640px;
}

.image_container:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.title {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    color: #FFFFFF;
}

.body {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 20px 0 40px;
}

.button {
    display: inline-block;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 30px;
    cursor: pointer;
}

.top_text_container {
    padding: 0 80px 0 130px;
}

.bottom_text_container {
    padding: 0 130px 0 80px;
}

@media screen and (max-width: 768px) {
    .image_container {
        display: none;
    }
    
    .top_text_container {
        padding: 0;
    }

    .bottom_text_container {
        padding: 0;
    }
}