.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    color: #100903;
}

.subtitle {
    margin-top: 12px;
    margin-bottom: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #100903;
}

.header {
    font-family: 'Inter';
    font-size: 20px;
    margin-bottom: 10px;
}

.select {
    background: #FFFFFF;
    border: 1px solid #CCC8C2;
    padding: 10px 16px;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #100903;
    margin-bottom: 20px;
    width: 100%;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
}

.conditional_form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
}

.text_box {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 8px;
    padding: 10px 16px;
    width: 100%;
    border: 1px solid #CCC8C2
}

.text_box_container {
    margin-right: 34px;
}

.form_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(20px, auto);
}

.input_label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #100903;
    margin-bottom: 4px;
}

.input {
    background: #FFFFFF;
    border: 1px solid #CCC8C2;
    border-radius: 8px;
    width: 100%;
    padding: 10px 16px;
    width: calc(100% - 32px);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #100903;
}

.primary_button {
    background: #489F67;
    border: 2px solid #489F67;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 24px;
    display: inline-block;
    cursor: pointer;
    margin-top: 20px;
}

.delete_button {
    background: #EB5757;
    border: 2px solid #EB5757;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 24px;
    display: inline-block;
    cursor: pointer;
}

.section_container {
    padding-bottom: 60px;
}

.conditional_section_container {
    padding-top: 30px;
}