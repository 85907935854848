.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    color: #100903;
}

.subtitle {
    margin-top: 12px;
    margin-bottom: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #100903;
}

.form_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(20px, auto);
}

.input_label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #100903;
    margin-bottom: 4px;
}

.input {
    background: #FFFFFF;
    border: 1px solid #CCC8C2;
    border-radius: 8px;
    width: 100%;
    padding: 10px 16px;
    width: calc(100% - 32px);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #100903;
}

.primary_button {
    background: #489F67;
    border: 2px solid #489F67;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 24px;
    display: inline-block;
    cursor: pointer;
}

.delete_button {
    background: #EB5757;
    border: 2px solid #EB5757;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 24px;
    display: inline-block;
    cursor: pointer;
}

.section_container {
    padding-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid rgba(0,0,0,0.2);
}

.row {
    width: 100%;
    display: flex;
    align-items: center;
}

.toggle_visibility {
    color: #489F67;
    cursor: pointer;
}

/* Updated .row class */
.row {
    display: flex;
    justify-content: space-between; /* This will space out the children */
    align-items: center;
    width: 100%;
}

/* Styling for the giveaway name and toggle */
.giveaway_name {
    flex-grow: 1; /* Allows the giveaway name to take up available space */
    margin-right: 16px; /* Optional: Adjust as needed for spacing */
}

.toggle_visibility {
    color: #489F67;
    cursor: pointer;
    margin-left: auto; /* Pushes the toggle to the left */
}

.name {
    font-weight: 800;
}

.details {
    padding-top: 20px;
    padding-bottom: 20px;
}

.details_list {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
}