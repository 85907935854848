.container {
    background-color: #009146;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.left {
    flex: 1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 20px 0 17px;
}

.left_inner {
    padding: 9px 0 6px 64px;
    cursor: pointer;
}

.right {
    flex: 1;
    text-align: right;
    padding: 10px 24px 10px 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #FFFFFF;
}

.mobile_right {
    display: none;
    width: 32px;
    margin-right: 16px;
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(185deg) brightness(105%) contrast(102%);
}

.navigation_item {
    position: relative;
    display: inline-block;
    margin-right: 32px;
    cursor: pointer;
    line-height: 40px;
}

.navigation_item:hover {
    color: #FADF4B;
    text-decoration: underline;
    text-underline-position: under;
}

.popover {
    z-index: 100;
    position: absolute;
    transform: translate(-5%, 35px);
    top: 5px;
    width: 196px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    border-radius: 4px;
    display: none;
    padding: 24px;
}

.popover_item {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #17130F;
    margin-bottom: 24px;
    cursor: pointer;
}

.popover_item:last-of-type {
    margin-bottom: 0;
}

.popover_item:hover {
    text-decoration: underline;
    text-underline-position: under;
}

.navigation_item_container {
    position: relative;
    display: inline-block;
}

.navigation_item_container:hover .popover {
    display: block;
}

.mobile_menu_container {
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.mobile_menu_header {
    height: 64px;
    width: calc(100% - 48px);
    background-color: white;
    border-bottom: 1px solid #cccccc;

    display: flex; 
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
}

.mobile_menu_body {
    flex: 1;
    overflow-y: scroll;
    background-color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #009146;
}

.mobile_menu_section {
    margin-bottom: 40px;
}

.mobile_menu_subsection {
    margin-top: 32px;
}

.mobile_menu_option {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .left_inner {
        padding: 9px 0 6px 16px;
    }

    .right {
        display: none;
    }

    .mobile_right {
        display: block;
    }
}