.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    color: #100903;
}

.subtitle {
    margin-top: 12px;
    margin-bottom: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #100903;
}

.form_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(20px, auto);
}

.input_label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #100903;
    margin-bottom: 4px;
}

.input {
    background: #FFFFFF;
    border: 1px solid #CCC8C2;
    border-radius: 8px;
    width: 100%;
    padding: 10px 16px;
    width: calc(100% - 32px);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #100903;
}

.primary_button {
    background: #489F67;
    border: 2px solid #489F67;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 24px;
    display: inline-block;
    cursor: pointer;
}

.delete_button {
    background: #EB5757;
    border: 2px solid #EB5757;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 24px;
    display: inline-block;
    cursor: pointer;
}

.section_container {
    padding-bottom: 60px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}