.container {
    background-color: black;
    border-radius: 8px;
    padding: 40px;
    width: calc(100% - 80px);
}

.title {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 43px;
    color: #FFFFFF;
}

.subtitle {
    margin-top: 16px;
    margin-bottom: 32px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.apple_store {
    height: 40px;
    cursor: pointer;
}

.google_store {
    height: 40px;
    cursor: pointer;
    margin-left: 26px;
}

@media screen and (max-width: 768px) {
    .left_inner {
        display: none;
    }

    .google_store {
        margin-top: 8px;
        margin-left: 0px;
    }
}