.card {
    flex: 1;
    padding: 64px 32px 32px;
    background: #dbd5ce;
    border-radius: 16px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #504F4D;
    max-width: 320px;
}

.card_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
}

.container {
    background-color: #e4e0db;
}

.content_container {
    padding: 40px 80px;
}

.description {
    font-size: 14px;
    line-height: 20px;
    padding-top: 24px;
    padding-bottom: 0;
    min-width: 300px;
}

.header {
    font-family: 'Playfair';
    font-weight: 400;
    font-size: 40px;
    line-height: 53.32px;
    color: #100903;
}

.image {
    width: auto;
    height: 120px;
}

.navigation_container {
    position: sticky;
    top: 0;
    z-index: 9999;
}

.pill {
    display: inline-block;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #504F4D;
    padding: 11px 20px;
    border: 2px solid #c6c3bf;
    border-radius: 8px;
    margin-right: 20px;
    cursor: pointer;
}

.pill_selected {
    font-weight: 700;
    font-size: 16px;
    border: 2px solid #504F4D;
}

.pill:hover {
    background-color: #CDC9C4;
}

.pill_container {
    margin-top: 24px;
    margin-left: -8px;

}

.pill_text {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 19.36px;
    color: #504F4D;
}

.pill_text:selected {
    font-weight: 700;
}

.price {
    padding: 20px 0;
}

.section {
    font-family: 'Playfair';
    font-weight: 500;
    font-size: 16px;
    line-height: 21.33px;
    color: #100903;
}

.title {
    margin-top: 8px;
    padding-bottom: 20px;
}