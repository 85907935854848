.container {
    background-color: #000000;
}

.top {
    
}

.top_container {
    padding: 50px 80px 120px;
    display: flex;
    flex-direction: row;
}

.top_column {
    flex: 1;
    color: white;
}

.navigation_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    color: #FFFFFF;
}

.navigation_item {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    color: #FFFFFF;
    cursor: pointer;
}

.navigation_item:hover {
    color: #FADF4B;
    text-decoration: underline;
    text-underline-position: under;
}

.navigation_item_bottom {
    display: inline-block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    color: #FFFFFF;
    cursor: pointer;
}

.navigation_item_bottom:hover {
    color: #FADF4B;
    text-decoration: underline;
    text-underline-position: under;
}

.bottom {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 0px 80px 20px;
}

.bottom_container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.bottom_border {
    width: 100%;
    height: 1px;
    background-color: #282828;
}

.bottom_left {
    flex: 1;
}

.bottom_right {
    flex: 1;
    text-align: right;
    display: inline-block;
}

@media screen and (max-width: 768px) {
    .top_container {
        display: block
    }

    .top_column {
        margin-top: 16px;
    }

    .bottom_container {
        display: block;
        text-align: center;
    }
    
    .bottom_right {
        text-align: center;
        margin: 24px 0 12px;
    }
}