.navigation_container {
    position: sticky;
    top: 0;
    z-index: 9999;
}

.container {
    background-color: #e4e0db;
}

.content_container {
    padding: 40px 80px;
    max-width: 1140px;
    margin: 0 auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #100903;
    overflow: hidden;
}

a, a.visited {
    color: #009146;
    text-decoration: none;
    border-bottom: 1px solid #009146;
}

a:hover {
    font-weight: 500;
    border-bottom: 2px solid #009146;
}

.breadcrumb {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #100903;
    margin-bottom: 8px;
}

h1 {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 53px;

    color: #100903;
}

.img {
    max-width: 100%;
    height: auto;
    max-height: 670px;
    background-size: cover;
    margin: 12px 0;
}

.button {
    display: inline-block;
    background: #009146;
    padding: 14px 20px;
    border-radius: 999px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    cursor: pointer;
    margin: 0 20px 0 0;
    text-decoration: none;
    transition: 0.5s;
}

.button:hover {
    display: inline-block;
    background: #100903;
    padding: 14px 30px;
    border-radius: 999px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    cursor: pointer;
    margin: 0 20px 0 0;
    text-decoration: none;
    border-bottom: 0;
}

hr {
    width: 100%;
    height: 1px;
    background-color: black;
    opacity: 0.2;
    margin: 40px 0;
}

.iframe_container {
    margin-top: 20px;
    position: relative;
    padding-top: 56.25%;
    max-width: 1280px;
}

.iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .content_container {
        padding: 40px 36px;
    }
}

ul {
    margin-left: 32px;
}

li {
    margin-top: 8px;
}