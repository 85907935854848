.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    color: #100903;
}

.subtitle {
    margin-top: 12px;
    margin-bottom: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #100903;
    display: inline-block;
}

.accordian_container {
    margin-bottom: 40px;
}

.accordian_header {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    max-width: 90%;
}

.accordian_header_wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px;
}

.accordian_wrapper {
    border-top: 1px solid #BDBAB6;
}

.accordian_wrapper:last-child {
    border-bottom: 1px solid #BDBAB6;
}

.accordian_body {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    padding-bottom: 20px;
    color: #100903;
}

.sign {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #489F67;
}

.section_header {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 20px;
    line-height: 24.2px;
    color: #100903;
    margin-bottom: 16px;
}

.underline {
    display: inline-block;
    text-decoration: underline;
}

.underline:hover {
    cursor: pointer;
    color: #489F67;
}