.container {
    background-color: #e4e0db;
}

.navigation_container {
    position: sticky;
    top: 0;
    z-index: 9999;
}

@media screen and (max-width: 768px) {
    
}